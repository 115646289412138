@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800;900&display=fallback");
@import url("https://fonts.googleapis.com/css2?family=Baskervville&display=swap");

@tailwind base;
@tailwind components;

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
@import "additional-styles/toggle-switch.scss";
@import "additional-styles/theme.scss";

@tailwind utilities;

// Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive
@responsive {
  .rtl {
    direction: rtl;
  }
}

// See Alpine.js: https://github.com/alpinejs/alpine#x-cloak
[x-cloak] {
  display: none;
}
#hsForm_a4a94835-b2bc-410c-aab9-121554cf6760
  > div.hs_submit.hs-submit
  > div.actions
  > input {
  background: #030f38 !important;
}

.hbspt-form {
  display: none;
}

.heading:after {
  content: " ";
  clear: both;
  width: 5em;
  display: block;
  margin: 0.6em 0 0.8em;
  border-bottom: 4px solid #0d0083;
}

section {
  scroll-margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  section {
    scroll-margin-top: 50px;
  }
}

// AOS styles
$aos-distance: 10px;
@import "node_modules/aos/src/sass/aos.scss";
